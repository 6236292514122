import React, { useEffect, useState, useCallback } from 'react';
import './App.css';
import Logo from './images/laacademia.png';
import Arriba from './images/right-arrow.png';
import Abajo from './images/downstairs.png';
import Profesor from './images/clase.png';

const DAY_NAMES = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
const MONTH_NAMES = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const CALENDARS = [
  { id: "537jo4ohjkas1g345d66bh96nc@group.calendar.google.com", aula: "Aula 1", icon: Abajo },
  { id: "nkhuid1sljvitcl01hv9hnmti8@group.calendar.google.com", aula: "Aula 2", icon: Abajo },
  { id: "nm23362atv2fkj2fqls8ngc9us@group.calendar.google.com", aula: "Aula 3", icon: Abajo },
  { id: "55pcag6s599tjcp5neu9e9m7vk@group.calendar.google.com", aula: "Aula 4", icon: Abajo },
  { id: "qll1l4heqobq7vhvhcenjsare0@group.calendar.google.com", aula: "Aula 5", icon: Arriba },
  { id: "r3ub7p56nji1q9vnp8ts1unjf8@group.calendar.google.com", aula: "Aula 6", icon: Arriba },
  { id: "hepu9f2pdvvjg06n85oddcq35g@group.calendar.google.com", aula: "Aula 5 + 6", icon: Arriba },
  { id: "dmg6vj60b2fstqtdj48p5e8dos@group.calendar.google.com", aula: "Aula 7", icon: Abajo },
  { id: "7ii1gv295qupa00nh2bg6oddfs@group.calendar.google.com", aula: "Aula 8 - OUI", icon: Arriba },
  { id: "19cb7cc7e5d0c21cc265303371f47a13d0d98aab8d254a72a1fe1ef82d3ae286@group.calendar.google.com", aula: "Aula 9", icon: Arriba }
];

const formatTimeNumber = (number) => number < 10 ? `0${number}` : number;

const App = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [events, setEvents] = useState({ current: [], upcoming: [] });

  const fetchEvents = useCallback(async () => {
    const now = new Date();
    const adjustedStartTime = new Date(now.getTime() + 15 * 60000);
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  
    const timeMin = adjustedStartTime.toISOString();
    const timeMax = endOfDay.toISOString();
  
    try {
      const responses = await Promise.all(CALENDARS.map(calendar =>
        fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendar.id}/events?timeMin=${timeMin}&timeMax=${timeMax}&key=${process.env.REACT_APP_CALENDAR_API_KEY}&orderBy=startTime&singleEvents=true`)
          .then(res => res.json())
      ));
  
      const allEvents = responses.flatMap((calendarData, index) => 
        calendarData.items?.map(event => {
          const startTime = new Date(event.start.dateTime);
          const endTime = new Date(event.end.dateTime);
          return {
            startTime,
            endTime,
            title: event.summary,
            subtitle: event.location,
            aula: CALENDARS[index].aula,
            icon: CALENDARS[index].icon
          };
        }) || []
      );

      const currentEvents = allEvents.filter(e => e.startTime <= now)
        .sort((a, b) => a.startTime - b.startTime);
      const upcomingEvents = allEvents.filter(e => e.startTime > now)
        .sort((a, b) => a.startTime - b.startTime);

        console.log(allEvents)
  
      setEvents({
        current: currentEvents,
        upcoming: upcomingEvents.slice(0, currentEvents.length > 0 ? currentEvents.length > 3 ? 5 : 10 : 15)
      });
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  }, []);

  useEffect(() => {
    const clockInterval = setInterval(() => setCurrentTime(new Date()), 1000);
    const eventsInterval = setInterval(fetchEvents, 900000); // 15 minutes

    fetchEvents();

    return () => {
      clearInterval(clockInterval);
      clearInterval(eventsInterval);
    };
  }, [fetchEvents]);

  const dateTimeString = `${DAY_NAMES[currentTime.getDay()]}, ${formatTimeNumber(currentTime.getDate())} de ${MONTH_NAMES[currentTime.getMonth()]}`;
  const timeString = `${formatTimeNumber(currentTime.getHours())}:${formatTimeNumber(currentTime.getMinutes())}`;

  return (
    <div className="app-container">
      <header>
        <img className='logo' src={Logo} alt='Logo Laacademia' />
        <div className='date-time'>
          <div id='date'>{dateTimeString}</div>
          <div id='time'>{timeString}</div>
        </div>
      </header>

      <main className='content'>
        {events.current.length === 0 && events.upcoming.length === 0 ? (
          <div className='no-classes'>
            <img className='no-classes-icon' src={Profesor} alt='' />
            No hay más clases hoy
          </div>
        ) : (
          <>
            {events.current.length > 0 && (
              <div className="events-section">
                <div className='section-title'>Ahora</div>
                <div className='section-now'>
                  {events.current.map((event, index) => <Event key={index} event={event} lg />)}
                </div>
              </div>
            )}
            {events.upcoming.length > 0 && (
              <div className="events-section">
                <div className='section-title'>Próximas clases</div>
                <div className='section-next'>
                  {events.upcoming.map((event, index) => <Event key={index} event={event} />)}
                </div>
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
};

const Event = React.memo(({ event, lg }) => {
  return (
    <div className={lg ? 'event-large' : 'event-small'}>
      <div className='event-time'>
        <div className='event-hour'>{formatTimeNumber(event.startTime.getHours())}</div>
        <div className='event-minutes'>{formatTimeNumber(event.startTime.getMinutes())}</div>
      </div>
      <div className='event-text'>
        <div className='event-text-sub'>
          <div className='event-title'>{event.title}</div>
          <div className='event-subtitle'>{event.subtitle}</div>
        </div>
        <div className='event-aula'>
          {event.aula}
          {event.icon && <img className='event-aula-icon' src={event.icon} alt='' />}
        </div>
      </div>
    </div>
  );
});

export default App;